<template>
	<div>
		<main-header></main-header>
		<div class="container--center access">
			<i class="fas fa-exclamation-triangle fa-6x icon"></i>
			<p class="accesscontent__text--lg">
				서비스 이용에 불편을 드려 죄송합니다.<br />해당 페이지는
				<strong>기업회원</strong>만 접근이 가능한 페이지입니다.
			</p>
			<p class="accesscontent__text--sm">
				해당 페이지를 이용하시려면 일반회원에서 기업회원으로 전환하셔야
				합니다.<br />
				관련하여 문의 사항이 있으시면 고객센터로 문의해 주시기 바랍니다.
			</p>
			<span>고객센터: 031-000-0000</span>
			<div>
				<button class="btn--md btn--fill-accent">기업회원 가입</button>
				<button class="btn--md btn--fill-gray">홈페이지</button>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import Footer from '../components/Footer.vue';
import MainHeader from '../components/MainHeader.vue';
export default {
	components: { MainHeader, Footer },
};
</script>

<style></style>
